
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import Layout from '../components/layout'

const IndexPage = () => (
	<Layout>
		<div className="container flex-div center-v center-h column">
			<h1 className="title-name">Lucas Eduardo Pessoa</h1>
			<div className="flex-div">
			<a href="https://github.com/lucas-pessoa" rel="noopener noreferrer">
				<div className="card gray">
					<div className="white-card"></div>
					<div className="icon">
						<FontAwesomeIcon icon={fab.faGithub} />
					</div>
					<div className="title">
						Github
					</div>
				</div>
			</a>
			<a href="https://medium.com/@lucas_pessoa" rel="noopener noreferrer">
				<div className="card green">
					<div className="white-card"></div>
					<div className="icon">
						<FontAwesomeIcon icon={fab.faMedium} />
					</div>
					<div className="title">
						Medium
					</div>
				</div>
			</a>
			<a href="https://www.linkedin.com/in/lucas-eduardo-pessoa/" target="_blank" rel="noopener noreferrer">
				<div className="card blue">
					<div className="white-card"></div>
					<div className="icon">
						<FontAwesomeIcon icon={fab.faLinkedin} />
					</div>
					<div className="title">
						LinkedIn
					</div>
				</div>
			</a>
			<a href="mailto:lucas@lucaspessoa.com" target="_blank" rel="noopener noreferrer">
				<div className="card yellow">
					<div className="white-card"></div>
					<div className="icon">
						<FontAwesomeIcon icon={far.faEnvelope} />
					</div>
					<div className="title">
						E-mail
					</div>
				</div>
			</a>
			</div>
		</div>
	</Layout>
)

export default IndexPage