import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import '../assets/styles.scss'
import './layout.css'

const Layout = ({ children }) => (
	<StaticQuery
		query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
		render={data => (
			<>
				<Helmet
					title={data.site.siteMetadata.title}
					meta={[
						{ name: 'author', content: 'Lucas Eduardo Pessoa' },
						{ name: 'description', content: 'Lucas Eduardo Pessoa - Personal Page' },
						{ name: 'keywords', content: 'coding, programming, computing, development, web, contact, lucas, eduardo, pessoa' },
					]}
				>
					<html lang="en" />
				</Helmet>
				<div className="mainDiv">
					{children}
				</div>
			</>
		)}
	/>
)

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
